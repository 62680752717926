@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nav-height: 5rem;
  --side-nav-footer-height: 12.563rem;
  --body-height: calc(100vh - env(safe-area-inset-bottom, 1rem));
  --announcement-height: 3.5rem;
  --announcement-height-mobile: 4.75rem;
  --curve: cubic-bezier(0, 0.84, 0.24, 1);
}

@layer base {
  body {
    @apply antialiased;
  }
}

@layer components {
  .link {
    @apply hover:underline;
    text-underline-offset: 2px;
  }

  .link--primary {
    @apply text-leland-primary;
  }

  /* nprogress bar color overrides */
  #nprogress .bar {
    @apply bg-leland-primary h-1 !important;
  }

  #nprogress .peg {
    @apply shadow-md shadow-leland-primary !important;
  }

  #nprogress .spinner-icon {
    @apply border-leland-primary !important;
  }
}

@layer utilities {
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .title-text {
    @apply text-5xl font-semibold;
  }

  .heading-text {
    @apply text-4xl font-semibold;
  }

  .subheading-text {
    @apply text-2xl font-semibold;
  }

  .detail-text {
    @apply text-sm font-medium uppercase;
  }

  .announcement-banner {
    height: var(--announcement-height-mobile);
  }

  .push-nav {
    padding-top: var(--nav-height);
  }

  .push-nav-and-announcement {
    padding-top: calc(var(--nav-height) + var(--announcement-height-mobile));
  }

  .container-def {
    @apply max-w-1500 mx-auto;
  }

  .link {
    @apply hover:underline underline-offset-2;
  }

  .navigation-sidebar-body {
    @apply mt-20;
    height: calc(
      var(--body-height) - (var(--nav-height) + var(--side-nav-footer-height))
    );
  }

  @screen lg {
    .title-text {
      @apply text-7xl;
    }

    .detail-text {
      @apply text-base;
    }

    .announcement-banner {
      height: var(--announcement-height);
    }

    .push-nav {
      padding-top: var(--nav-height);
    }
  }

  .has-shadow {
    box-shadow:
      0px 12px 12px rgba(0, 0, 0, 0.03),
      0px 2px 2px rgba(0, 0, 0, 0.06);
  }
}

@keyframes enterIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.enter-in {
  opacity: 0;
  transform: translateY(100px);
  animation: enterIn 1s forwards var(--curve);
}
